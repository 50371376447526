export default function Loader() {
    return (
        <>
            <div className="flex flex-col items-center justify-center mt-20">
                <div className="absolute m-20"></div>
                <img src="/images/palestine-flag.svg" className="animate-pulse rounded-md h-60 w-60" alt="Bahriya Logo" />
            </div>
        </>
    );

}