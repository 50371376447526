import ClassNames from '../../utils/ClassNames.js';
import {useNavigate} from "react-router-dom";
const NavigationItem = (props) => {
var item = props.item;
const navigate = useNavigate();
    return (
        <>
        <a
            key={item.name}
            href={item.href}
            target={item.target}
            rel={item.rel}
            title={item.name}
            onClick={
                (e) => {
                    e.preventDefault();
                    navigate(item.href);
                }
            }
            className={ClassNames(
                item.current
                    ? 'bg-red-600 text-white'
                    : 'text-white hover:bg-black hover:text-white',
                'px-3 py-2 rounded-md text-sm font-medium'
            )}
            aria-current={item.current ? 'page' : undefined}
        >
            {item.name}
        </a>
        </>
    );
  
}

export default NavigationItem;