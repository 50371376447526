import {useNavigate} from "react-router-dom";

export default function CompaniesList(props) {
    const companies = props.companies;
    const navigate = useNavigate();
    return (
        <ul className="divide-y divide-gray-200 bg-white px-10 py:1">
            {companies.map((company) => (
                <li key={company.id} className="">
                    <div id={"company-" + company.id} className="flex items-center justify-between lg:gap-x-6 gap-y-2 py-2 w-full">

                        <div id={company.id + "-info"}
                             className="mt-1 flex items-center gap-x-2 w-1/2 border-r border-gray-200 min-h-18">
                            <a href={"/company/" + company.id} title={company.name}
                               onClick={
                                   (e) => {
                                       e.preventDefault();
                                       navigate("/company/" + company.id);
                                   }
                               }>
                                <img className="flex w-100 md:min-h-10 h-10 border border-blue-400 rounded-md p-1"
                                     src={company.logo.url} alt={company.name}
                                     title={company.name}/>
                            </a>
                            <a href={"/company/" + company.id}
                               onClick={
                                   (e) => {
                                       e.preventDefault();
                                       navigate("/company/" + company.id);
                                   }
                               }
                               title={company.name} className="hover:text-blue-400">
                                <span className="flex text-sm font-semibold">{company.name}</span>
                            </a>
                        </div>

                        <div id={company.id + "-alternatives"} className="flex items-center gap-x-2 w-1/2 min-h-18">
                            <dl>
                                <dt className="sr-only">Alternatives</dt>
                                <dd>
                                    <ul className="flex items-center gap-x-2">
                                        {company.alternatives.map((alternative) => (
                                            <li key={alternative.id} className="flex items-center gap-x-2">
                                                <a href={alternative.website} title={alternative.name} rel="nofollow noreferrer" target="_blank">
                                                    <img className="w-100 h-10 p-1 border border-green-600 rounded-md"
                                                        src={alternative.logo.url}
                                                        alt={alternative.name}
                                                        title={alternative.name}
                                                    />
                                                </a>
                                            </li>
                                            ))}
                                    </ul>
                                </dd>
                            </dl>
                        </div>
                    </div>

                    <div id={company.id + "-labels"} className="flex items-center justify-between gap-x-6 mb-3 w-full">
                        <div className="flex mt-1 items-center gap-x-2 text-xs leading-5 text-gray-500 w-1/2">
                            <ul className="flex items-center gap-x-2">
                                <li key={company.id + "-boycott"} className="flex items-center gap-x-2">
                                    <img
                                        className="h-8 rounded-md"
                                        src="/images/boycott-israel.svg"
                                        alt="Boycott Israeli Businesses"
                                        title="Boycott this Israeli Businesses"
                                    />
                                </li>
                                {company.categories.map((category) => (
                                    <li key={category.id} className="flex items-center gap-x-2 max-lg:hidden">
                                        <span className="bg-green-200 p-1 rounded-md">
                                            {category.name}
                                        </span>
                                    </li>
                                ))}
                                <li key={company.type} className="flex items-center gap-x-2 max-sm:hidden">
                                    <span className="bg-gray-200 p-1 rounded-md">
                                        {company.type.split('-')
                                            .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                                            .join(' ')}
                                        </span>
                                </li>
                            </ul>
                        </div>
                        <div className="flex mt-1 items-center gap-x-2 text-xs leading-5 text-gray-500 w-1/2">
                            <img
                                className="md:min-h-7 h-7 rounded-md"
                                src="/images/palestine-flag.svg"
                                alt="Palestine flag"
                                title="Palestine friendly"
                            />
                            <span className="bg-green-900 text-white p-1 rounded-md max-sm:hidden">Alternatives</span>
                        </div>
                    </div>
                </li>

            ))}
        </ul>
    )
}
