import MobileNavigationItem from "../atoms/MobileNavigationItem";
import {Disclosure} from "@headlessui/react";
import {useNavigate} from "react-router-dom";
import CategoriesNavigation from "../molecules/CategoriesNavigation";
import ArticlesNavigation from "../molecules/ArticlesNavigation";

export default function MobileNavbar(props) {
    const navigation = props.navigation;
    const categories = props.categories;
    const articles = props.articles;
    const navigate = useNavigate();

    return (
      <>
          <Disclosure.Panel className="md:hidden">
              <div className="space-y-1 px-2 pt-2 pb-3 sm:px-3">
                  {navigation.map((item) => (
                      <MobileNavigationItem key={item.id} item={item} />
                  ))}
                  <CategoriesNavigation categories={categories} />
                  <ArticlesNavigation articles={articles} />
              </div>
          </Disclosure.Panel>
      </>
    );
}