
import {Fragment} from 'react'
import Navbar from "../../organisms/Navbar";
import Footer from "../../organisms/Footer";
import {PageTitle} from "../../atoms/PageTitle";

export default function WhyIsraelWillContinueInTurmoil() {

        return (
            <>
                <PageTitle title="Why Israel will Continue to Be in Turmoil?"/>
                <Navbar key="navbar"/>
                <header className="bg-gray-100 h-full">
                    <div className="mx-auto py-1 px-7 h-full">
                        <h1 className="text-3xl font-bold tracking-tight text-gray-900 sm:mt-7 sm:text-4xl">Why Israel will Continue to Be in Turmoil?</h1>
                    </div>
                </header>
                <main className="bg-gray-100 h-full mb-11">
                    <div className="mx-auto py-4 px-7 h-full text-md leading-8 text-gray-600">
                        <p className="">
                            Logically and metaphysically, the answer to this question is rather simple. A nation formed
                            with corruption or dishonesty at its foundation will never know peace. It will always be in
                            turmoil. This a simple rule of the universe. God has created everything in balance, so the
                            citizens of Israel - despite the fact that not all are Zionists and many are not even aware
                            of the
                            truth around the creation of the state - must reap what their forefathers sowed for them.
                        </p>
                        <p className="mt-3">
                            Instead of rewriting this history, I will simply quote from the book of the grandson of a
                            Jewish Rabbi, who
                            having visited Palestine in the 1920s, and having had conversations with the Zionist
                            settlers there, was so
                            disturbed by their ill nature and their intentions that he recalls these conversations in a
                            book he wrote later (and this probably
                            played a part in his later becoming a Muslim).
                            The book is called the <i>Road to Mecca</i>, and the author is Muhammad Asad (who before
                            becoming a Muslim, was known as Leopold Weiss). He writes
                            of his time in Palestine:
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            And there were the Jews: indigenous Jews, wearing a tarbūsh and a wide, voluminous cloak, in
                            their facial type strongly resembling the Arabs;
                            Jews from Poland and Russia, who seemed to carry with them so much of the smallness and
                            narrowness of their past lives in Europe that it was
                            surprising to think they claimed to be of the same stock as the proud Jew from Morocco or
                            Tunisia with his white burnus. But although the European
                            Jews were so obviously out of all harmony with the picture that surrounded them, it was they
                            who set the tone of Jewish life and politics and thus
                            seemed to be responsible for the almost visible friction between Jews and Arabs.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            What did the average European know of the Arabs in those days? Practically nothing. When he
                            came to the Near East he brought with him some
                            romantic and erroneous notions; and if he was well-intentioned and intellectually honest, he
                            had to admit that he had no idea at all about the Arabs.
                            I, too, before I came to Palestine, had never thought of it as an Arab land. I had, of
                            course, vaguely known that 'some' Arabs lived there, but I
                            imagined them to be only nomads in desert tents and idyllic oasis dwellers. Because most of
                            what I had read about Palestine in earlier days had
                            been written by Zionists - who naturally had only their own problems in view - I had not
                            realized that the towns also were full of Arabs - that, in
                            fact, <u>in 1922 there lived in Palestine nearly five Arabs to every Jew, and that,
                            therefore, it was an Arab country to a far higher degree than a
                            country of Jews</u>.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            When I remarked on this to Mr. Ussyshkin, chairman of the Zionist Committee of Action, whom
                            I met during that time, I had the impression that
                            the Zionists were not inclined to give much consideration to the fact of Arab majority; nor
                            did they seem to attribute any real importance to the
                            Arabs' opposition to Zionism. Mr. Ussyshkin's response showed nothing but contempt for the
                            Arabs:
                            'There is no real Arab movement here against us; that is, no movement with roots in the
                            people. All that you regard as opposition is in reality
                            nothing but the shouting of a few disgruntled agitators. It will collapse of itself within a
                            few months or at most a few years.'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            This argument was far from satisfactory to me. From the very beginning I had a feeling that
                            the whole idea of Jewish settlement in Palestine was
                            artificial, and, what was worse, that it threatened to transfer all the complications and
                            insoluble problems of European life into a country which
                            might have remained happier without them. The Jews were not really coming to it as one
                            returns to one's homeland; they were rather bent on
                            making it into a homeland conceived on European patterns and with European aims. In short,
                            they were strangers within the gates. And so I did
                            not find anything wrong in the Arabs' determined resistance to the idea ofa Jewish homeland
                            in their midst; on the contrary, I immediately realized
                            that it was the Arabs who were being imposed upon and were rightly defending themselves
                            against such an imposition.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            In the Balfour Declaration of 1917, which promised the Jews a 'national home' in Palestine,
                            I saw a cruel political manoeuvre designed to foster the
                            old principle, common to all colonial powers, of 'divide and rule'. In the case of
                            Palestine, this principle was the more flagrant as in 1916 the British
                            had promised the then ruler of Mecca, Sharif Husayn, as a price for his help against the
                            Turks, an independent Arab state which was to comprise
                            all countries between the Mediterranean Sea and the Persian Gulf. They not only broke their
                            promise a year later by concluding with France the
                            secret Sykes-Picot Agreement (which established French Dominion over Syria and the Lebanon),
                            but also, by implication, excluded Palestine from
                            the obligations they had assumed with regard to the Arabs.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            Although of Jewish origin myself, I conceived from the outset a strong objection to Zionism.
                            Apart from my personal sympathy for the Arabs, I
                            considered it immoral that immigrants, assisted by a foreign Great Power, should come from
                            abroad with the avowed intention of attaining to
                            majority in the country and thus to dispossess the people whose country it had been since
                            time immemorial. Consequently, I was inclined to take
                            the side of the Arabs whenever the Jewish-Arab question was brought up - which, of course,
                            happened very often. This attitude of mine was
                            beyond the comprehension of practically all the Jews with whom I came in contact during
                            those months. They could not understand what I saw in
                            the Arabs who, according to them, were no more than a mass of backward people whom they
                            looked upon with a feeling not much different from
                            that of the European settlers in Central Africa. They were not in the least interested in
                            what theArabs thought; almost none of them took pains to
                            learn Arabic;and everyone accepted without question the dictum that Palestine was the
                            rightful heritage of the Jews.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            I still remember a brief discussion I had on this score with Dr. Chaim Weizmann, the
                            undisputed leader of the Zionist movement. He had come on
                            one of his periodic visits to Palestine (his permanent residence was, I believe, in London),
                            and I met him in the house of a Jewish friend. One could
                            not but be impressed by the boundless energy of this man - an energy that manifested itself
                            even in his bodily movements, in the long, springy stride
                            with which he paced up and down the room- and by the power of intellect revealed in the
                            broad forehead and the penetrating glance of his eyes.
                            He was talking of the financial difficulties which were besetting the dream of a Jewish
                            National Home, and the insufficient response to this dream
                            among people abroad; and I had the disturbing impression that even he, like most of the
                            other Zionists, was inclined to transfer the moral
                            responsibility for all that was happening in Palestine to the' outside world'.
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            This impelled me to
                            break through the deferential hush with which all the
                            other people present were listening to him,and to ask:
                            'And what about the Arabs?'</p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            I must have committed a faux pas by thus bringing a jarring note into the conversation, for
                            Dr. Weizmann turned his face slowly toward me, put
                            down the cup he had been holding in his hand,and repeated my question:
                            'What about the Arabs ...?’
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            'Well - how can you ever hope to make Palestine your homeland in the face of the vehement
                            opposition of the Arabs who, after all, are in the
                            majority in this country?'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            The Zionist leader shrugged his shoulder sand answered drily: 'We expect they won't be in a
                            majority after a few years.'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            'Perhaps so. You have been dealing with this problem for years and must know the situation
                            better than I do. But quite apart from the political
                            difficulties which Arab opposition may or may not put in your way - does not the moral
                            aspect of the question ever bother you? Don't you think
                            that it is wrong on your part to displace the people who have always lived in this country?'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            'But it is our country,' replied Dr. Weizmann, raising his eyebrows. 'We are doing no more
                            than taking back what we have been wrongly deprived
                            of.'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            'But you have been away from Palestine for nearly two thousand years! Before that you had
                            ruled this country, and hardly ever the whole of it, for
                            less than five hundred years. Don't you think that the Arabs could, with equal
                            justification, demand Spain for themselves - for, after all, they held
                            sway in Spain for nearly seven hundred years and lost it entirely only five hundred years
                            ago?'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            Dr. Weizmann had become visibly impatient:'Nonsense. The Arabs had only conquered Spain; it
                            had never been their original homeland, and so it
                            was only right that in the end theywere driven out by the Spaniards.'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            'Forgive me,' I retorted, 'but it seems to me that there is some historical oversight here.
                            After all, the Hebrews also came as conquerors to
                            Palestine. Long before them were many other Semitic and non-Semitic tribes settled here -
                            the Amorites, the Edomites, the Philistines, the
                            Moabites, and the Hittites. Those tribes continued living here even in the days of the
                            kingdoms of Israel and Judah. They continued living here after
                            the Romans drove our ancestors away. They are living here today. The Arabs who settled in
                            Syria and Palestine after their conquest in the seventh
                            century were always only a small minority of the population; the rest of what we describe
                            today as Palestinian or Syrian "Arabs" are in reality only
                            the Arabianized, original inhabitants of the country. Some of them became Muslims in the
                            course of centuries, others remained Christians; the
                            Muslims naturally inter-married with their co-religionists from Arabia. But can you deny
                            that the bulk of those people in Palestine, who speak
                            Arabic, whether Muslims or Christians, are direct-line descendants of the original
                            inhabitants: original in the sense of having lived in this country
                            centuries before the Hebrews came to it?'
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            Dr. Weizmann smiled politely at my outburst and turned the conversation to other topics. I
                            did not feel happy about the outcome of my
                            intervention. I had of course not expected any of those present - least of all Dr. Weizmann
                            himself - to subscribe to my conviction that the Zionist
                            idea was highly vulnerable on the moral plane: but I had hoped that my defence of the Arab
                            cause would at least give rise to some sort of
                            uneasiness on the part of the Zionist leadership - an uneasiness which might bring about
                            more introspection and thus, perhaps, a greater readiness
                            to admit the existence of a possible moral right in the opposition of the Arabs. . . None of
                            this had come about. Instead, I found myself facing a
                            blank wall of staring eyes: a censorious disapproval of my temerity, which dared question
                            the unquestionable right of the Jews to the land of their
                            forefathers...
                        </p>
                        <p className="mt-3 block text-gray-500 mx-3 mt-3 border-l pl-3 font-semibold">
                            How was it possible, I wondered, for people endowed with so much creative intelligence as
                            the Jews to think of the Zionist-Arab conflict in Jewish
                            terms alone? Did they not realize that the problem of the Jews in Palestine could, in the
                            long run, be solved only through friendly co-operation with
                            the Arabs? Were they so hopelessly blind to the painful future which their policy must
                            bring? - to the struggles, the bitterness and the hatred to
                            which the Jewish island, even if temporarily successful, would forever remain exposed in the
                            midst of a hostile Arab sea?
                            And how strange, I thought, that a nation which had suffered so many wrongs in the course of
                            its long and sorrowful diaspora was now, in single-minded
                            pursuit of its own goal, ready to inflict a grievous wrong on another nation - and a nation,
                            too, that was innocent of all that past Jewish
                            suffering. Such a phenomenon, I knew, was not unknown to history; but it made me,
                            nonetheless, very sad to see it enacted before my eyes.
                        </p>
                        <p className="mt-3">
                            The above excerpt clearly highlights the fact that the Zionists were not interested in
                            co-existing with the Arabs. They were not interested in peace. One who is not interested in
                            peace cannot expect peace.
                        </p>
                        <p className="mt-3">
                            It's unfortunate that many young Israelis are not aware of this history. We hope that this
                            well help them understand why they are in the situation they are in today and that they are
                            able to rise up against the tyrants who are leading them to their own destruction for their
                            own petty gains and egos.
                        </p>
                    </div>
                </main>
                <Footer/>
            </>
        );
};
