import ClassNames from '../../utils/ClassNames.js';
import {Disclosure} from "@headlessui/react";

const MobileNavigationItem = (props) => {
var item = props.item;
    return (
        <>
            <Disclosure.Button
                key={item.name}
                as="a"
                href={item.href}
                className={ClassNames(
                    item.current
                        ? 'bg-red-600 text-white'
                        : 'text-white bg-green-900 hover:bg-black',
                    'block px-3 py-2 rounded-md text-base font-medium'
                )}
                aria-current={item.current ? 'page' : undefined}
            >
                {item.name}
            </Disclosure.Button>
        </>
    );
  
}

export default MobileNavigationItem;