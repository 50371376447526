
import {Fragment, useEffect} from 'react'
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import {PageTitle} from "../atoms/PageTitle";
import Splash404 from "../organisms/Splash404";
import {useNavigate} from "react-router-dom";


export default function NotFound() {
    const navigate = useNavigate();

    useEffect(() => {
        async function goTo404() {
            let currentLoc = await window.location.pathname;
            if (currentLoc !== '/404') {
                window.location.href = '/404';
            }
        }
        goTo404();
    });

        return (
            <>
                <PageTitle title="Page Does Not Exist - Boycott Israeli Businesses"/>
                <Navbar key="navbar"/>
                <header className="bg-gray-100 h-full">
                    <div className="mx-auto py-4 px-7 h-full">
                        <Splash404/>
                    </div>
                </header>
                <main className="bg-gray-100 h-full"></main>
                <Footer/>
            </>
        );
};
