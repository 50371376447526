import {Popover, Transition} from "@headlessui/react";
import {ChevronDownIcon} from "@heroicons/react/20/solid";
import {Fragment} from "react";

export default function ArticlesNavigation(props)
{
    const articles = props.articles;
    return (
        <Popover className="relative">
            <Popover.Button as="a" className="w-full block px-3 py-2 rounded-md text-base font-medium inline-flex items-center gap-x-1 text-sm font-semibold leading-6 text-white bg-green-900 hover:bg-black">
                <span>Articles</span>
                <ChevronDownIcon className="h-5 w-5" aria-hidden="true" />
            </Popover.Button>

            <Transition
                as={Fragment}
                enter="transition ease-out duration-200"
                enterFrom="opacity-0 translate-y-1"
                enterTo="opacity-100 translate-y-0"
                leave="transition ease-in duration-150"
                leaveFrom="opacity-100 translate-y-0"
                leaveTo="opacity-0 translate-y-1"
            >
                <Popover.Panel className="absolute left-1/2 z-10 mt-5 flex w-screen max-w-min -translate-x-1/2 px-4">
                    <div className="w-56 shrink rounded-xl bg-white p-4 text-sm font-semibold leading-6 text-gray-900 shadow-lg ring-1 ring-gray-900/5">
                        {articles.map((item) => (
                            <a key={item.id} href={item.href} className="block p-2 hover:text-red-600">
                                {item.name}
                            </a>
                        ))}
                    </div>
                </Popover.Panel>
            </Transition>
        </Popover>
    );
}