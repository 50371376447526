import {Bars3Icon, XMarkIcon} from "@heroicons/react/24/outline";
import {Disclosure} from "@headlessui/react";

export default function MobileHamburger(open) {
    return (
      <>
          <Disclosure.Button
              className={"inline-flex items-center justify-center rounded-md p-2 text-white hover:bg-opacity-75 hover:text-white focus:outline-none focus:ring-2 focus:ring-white focus:ring-offset-2 focus:ring-offset-blue-600"}>
              <span className="sr-only">Open main menu</span>
              {open.open ? (
                  <XMarkIcon className="block h-6 w-6" aria-hidden="true" />
              ) : (
                  <Bars3Icon className="block h-6 w-6" aria-hidden="true" />
              )}
          </Disclosure.Button>
      </>
    );
}