export default function Splash404() {
    return (
        <div className="relative bg-gray-100">
            <div className="max-w-8xl lg:grid lg:grid-cols-12 lg:gap-x-2 lg:px-2">
                <div className="px-7 pb-24 pt-7 sm:pb-32 lg:col-span-8 lg:px-0 lg:pb-12 lg:pt-20 xl:col-span-6">
                    <div className="mx-auto max-w-2xl lg:mx-0">
                        <div className="flex">
                            <img
                                className="h-10"
                                src="/images/boycott-israel.svg"
                                alt="Boycott Israeli Businesses"
                            />
                            <img
                                className="h-8 ml-3 mt-1"
                                src="/images/palestine-flag.svg"
                                alt="Free Palestine"
                            />
                        </div>
                        <h1 className="mt-24 text-3xl font-bold tracking-tight text-gray-900 sm:mt-10 sm:text-4xl">
                            Page Not Found
                        </h1>
                        <p className="mt-6 text-lg leading-8 text-gray-600 mr-7">
                            Oops, sorry, you've stumbled upon a page that doesn't exist.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600 mr-7">
                            Use the navigation above to find what you're looking for.
                        </p>
                        <p className="mt-6 text-lg leading-8 text-gray-600 mr-7">
                            At boycottisraeli.biz, we're building a comprehensive directory of Israeli businesses so you can search for who to boycott and
                            see what alternatives are available.
                        </p>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a
                                href="/companies"
                                className="rounded-md bg-green-900 px-3.5 py-2.5 text-sm font-semibold text-white shadow-sm hover:bg-red-600 focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 focus-visible:outline-indigo-600"
                            >
                                Find Israeli Businesses and Alternatives
                            </a>
                        </div>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a href="/why-should-you-boycott"
                               className="text-sm font-semibold leading-6 text-green-900 hover:text-red-600">
                                Why should you boycott Israel? <span
                                aria-hidden="true">→</span>
                            </a>
                        </div>
                        <div className="mt-10 flex items-center gap-x-6">
                            <a href="/learn-why-israel-will-continue-in-turmoil"
                               className="text-sm font-semibold leading-6 text-green-900 hover:text-red-600">
                                Learn why Israel is going to continue be a nation in turmoil? <span
                                aria-hidden="true">→</span>
                            </a>
                        </div>
                    </div>
                </div>
                <div className="relative lg:col-span-5 lg:-mr-8 xl:absolute xl:inset-0 xl:left-1/2 xl:mr-0">
                    <img
                        className="aspect-[3/2] w-full border border-blue-900 bg-gray-50 object-cover lg:absolute lg:inset-0 lg:aspect-auto lg:h-full"
                        src="/images/boycott-israel.svg"
                        alt="Boycott Israeli Businesses"
                    />
                </div>
            </div>
        </div>
    )
}
