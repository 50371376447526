import React from "react";
import {BrowserRouter, Route, Routes} from "react-router-dom";
import Home from "./components/pages/Home";
import Companies from "./components/pages/Companies";
import WhyShouldYouBoycott from "./components/pages/articles/WhyShouldYouBoycott";
import WhyIsraelWillContinueInTurmoil from "./components/pages/articles/WhyIsraelWillContinueInTurmoil";
import Category from "./components/pages/Category";
import Company from "./components/pages/Company";
import NotFound from "./components/pages/NotFound";

function App() {
    return (
        <>
            <div>
                <BrowserRouter>
                    <Routes>
                        <Route path="*" element={
                            <NotFound/>
                        }/>
                        <Route path="/404" element={
                            <NotFound/>
                        }/>
                        <Route path="/" element={
                            <Home/>
                        }/>
                        <Route path="/companies" element={
                            <Companies/>
                        }/>
                        <Route path="/companies/:category" element={
                            <Category/>
                        }/>
                        <Route path="/company/:companyId" element={
                            <Company/>
                        }/>
                        <Route path="/why-should-you-boycott" element={
                            <WhyShouldYouBoycott/>
                        }/>
                        <Route path="/learn-why-israel-will-continue-in-turmoil" element={
                            <WhyIsraelWillContinueInTurmoil/>
                        }/>
                    </Routes>
                </BrowserRouter>
            </div>
        </>
    );
}

export default App;
