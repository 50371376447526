import Navigation from "../molecules/Navigation";
import MobileHamburger from "../atoms/MobileHamburger";

export default function DesktopNavBar(props)
{
    return (
        <>
            <div className="mx-auto max-w px-4 sm:px-6 lg:px-8 bg-green-900">
                <div className="flex h-12 items-center justify-between">
                    <div className="flex items-center w-2/3">
                        <Navigation key="desktop-nav" navigation={props.navigation} articles={props.articles} categories={props.categories} />
                    </div>
                    <div className="flex md:hidden w-1/3 items-end justify-end">
                        <MobileHamburger key="mobile-hamburger" open={props.open} />
                    </div>
                </div>
            </div>
        </>

    );
}