
import {Fragment} from 'react'
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import {PageTitle} from "../atoms/PageTitle";
import Splash from "../organisms/Splash";

export default function Home() {

        return (
            <>
                <PageTitle title="Boycott Israeli Businesses"/>
                <Navbar key="navbar"/>
                <header className="bg-gray-100 h-full">
                    <div className="mx-auto py-4 px-7 h-full">
                        <Splash/>
                    </div>
                </header>
                <main className="bg-gray-100 h-full"></main>
                <Footer/>
            </>
        );
};
