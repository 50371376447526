import {Fragment, useEffect, useState} from 'react';
import {useNavigate, useParams} from "react-router-dom";
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import {PageTitle} from "../atoms/PageTitle";

export default function Company() {
    const [company, setCompany] = useState([]);
    const {companyId} = useParams();
    const navigate = useNavigate();

    useEffect(() => {
        async function getCompany() {
            let response = await fetch('https://api.boycottisraeli.biz/v1/companies/' + companyId, {method: 'GET'});
            let status = await response.status
            if (status !== 200) {
                window.location.href = '/404';
            }
            let data = await response.json();
            setCompany(data.data[0]);

        }
        getCompany();
    }, [companyId]);

    return (

        <>
            <PageTitle title={company.name + " - Israeli Company to Boycott and its Alternatives"}/>
            <Navbar key="navbar"/>
            <header className="bg-gray-100 lg:px-40 sm:px-5">
                <h1 className="lg:pt-7 sm:pt-3 pb-7 sm:pb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                    {company.type !== undefined ?
                        company.type === "israeli" ? "Boycott " + company.name + " - an Israeli Business" :
                            "Boycott " + company.name + " - an Israeli Supporter" : ""}
                </h1>
            </header>
            <main className="bg-gray-100 lg:px-40 sm:px-1 mb-11">
                <div className="bg-white mx-auto lg:grid lg:grid-cols-12 px-10">
                    <div className="lg:col-span-12">
                        <div className="relative mt-2 flex items-center">
                            <div className="space-y-8 w-full">
                                <div
                                    key={company.id}
                                    className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                                >
                                    <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                        <div className="sm:flex lg:col-span-7">
                                            <div
                                                className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                                                <a href={company.website} rel="nofollow noreferrer"
                                                   target="_blank">
                                                    <img
                                                        src={company.logo !== undefined ? company.logo.url : "https://via.placeholder.com/150"}
                                                        alt={company.name}
                                                        className="flex w-100 md:min-h-50 h-50 border border-blue-400 rounded-md p-1"
                                                    />
                                                </a>
                                            </div>

                                            <div className="mt-6 sm:ml-6 sm:mt-0">
                                            <h3 className="text-base font-medium text-gray-900">
                                                    <a href={company.website} rel="nofollow noreferrer"
                                                       target="_blank">{company.name}</a>
                                                </h3>
                                                <p className="mt-3 text-sm text-gray-500">{company.description}</p>
                                            </div>
                                        </div>

                                        <div className="mt-6 lg:col-span-5 lg:mt-0">
                                            <dl className="grid grid-cols-2 gap-x-6 text-sm">
                                                <div>
                                                    <dt className="font-medium text-gray-900">Categories</dt>
                                                    <dd className="mt-3 text-gray-500">
                                                        {company.categories !== undefined ? company.categories.map((category) => (

                                                            <span key={category.id}
                                                                  className="bg-green-200 p-1 mb-1 rounded-md block">
                                                                        {category.name}
                                                                    </span>
                                                        )) : ""}
                                                    </dd>
                                                </div>
                                                <div>
                                                    <dt className="font-medium text-gray-900">Type</dt>
                                                    <dd className="mt-3 space-y-3 text-gray-500">
                                                        <div
                                                            className="mt-1 items-center gap-x-2 text-xs leading-5 text-gray-500 w-1/2">
                                                            <img
                                                                className="h-8 rounded-md block"
                                                                src="/images/boycott-israel.svg"
                                                                alt="Boycott Israeli Businesses"
                                                                title="Boycott this Israeli Businesses"
                                                            />
                                                            <br />
                                                            <span className="bg-gray-200 p-1 rounded-md block">
                                                                {company.type !== undefined ? company.type.split('-')
                                                                    .map((str) => str.charAt(0).toUpperCase() + str.slice(1))
                                                                    .join(' ') : ""}
                                                                </span>
                                                        </div>
                                                    </dd>
                                                </div>
                                            </dl>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <h2 className="lg:pt-7 sm:pt-3 pb-7 sm:pb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">Alternatives
                    to {company.name}</h2>
                {company.alternatives !== undefined ? company.alternatives.map((alternative) => (
                    <div className="bg-white mx-auto lg:grid lg:grid-cols-12 px-10">
                        <div className="lg:col-span-12">
                            <div className="relative mt-2 flex items-center">
                                <div className="space-y-8 w-full">
                                    <div
                                        key={company.id}
                                        className="border-b border-t border-gray-200 bg-white shadow-sm sm:rounded-lg sm:border"
                                    >
                                        <div className="px-4 py-6 sm:px-6 lg:grid lg:grid-cols-12 lg:gap-x-8 lg:p-8">
                                            <div className="sm:flex lg:col-span-7">
                                                <div
                                                    className="aspect-h-1 aspect-w-1 w-full flex-shrink-0 overflow-hidden rounded-lg sm:aspect-none sm:h-40 sm:w-40">
                                                    <a href={alternative.website}
                                                       rel="nofollow noreferrer"
                                                       title={alternative.name + " - Alternative to " + company.name}
                                                       target="_blank"
                                                    >
                                                        <img
                                                            src={alternative.logo !== undefined ? alternative.logo.url : "https://via.placeholder.com/150"}
                                                            alt={alternative.name}
                                                            className="flex w-100 md:min-h-50 h-50 border border-blue-400 rounded-md p-1"
                                                        />
                                                    </a>
                                                </div>

                                                <div className="mt-6 sm:ml-6 sm:mt-0">
                                                    <h3 className="text-base font-medium text-gray-900">
                                                        <a href={alternative.website}
                                                           title={alternative.name + " - Alternative to " + company.name}
                                                           rel="nofollow noreferrer"
                                                           target="_blank"
                                                        >
                                                            {alternative.name}
                                                        </a>
                                                    </h3>
                                                    <p className="mt-3 text-sm text-gray-500">{alternative.description}</p>
                                                </div>
                                            </div>

                                            <div className="mt-6 lg:col-span-5 lg:mt-0">
                                                <dl className="grid grid-cols-2 gap-x-6 text-sm">
                                                    <div>
                                                        <dt className="font-medium text-gray-900">Categories</dt>
                                                        <dd className="mt-3 text-gray-500">
                                                            {company.categories !== undefined ? company.categories.map((category) => (

                                                                <span key={category.id}
                                                                      className="bg-green-200 p-1 mb-1 rounded-md block">
                                                                        {category.name}
                                                                    </span>
                                                            )) : ""}
                                                        </dd>
                                                    </div>
                                                    <div>
                                                        <dt className="font-medium text-gray-900">Type</dt>
                                                        <dd className="mt-3 space-y-3 text-gray-500">
                                                            <div
                                                                className="mt-1 items-center gap-x-2 text-xs leading-5 text-gray-500 w-1/2">
                                                                <img
                                                                    className="md:min-h-7 h-7 rounded-md block mb-5"
                                                                    src="/images/palestine-flag.svg"
                                                                    alt="Palestine flag"
                                                                    title="Palestine friendly"
                                                                />
                                                                <span
                                                                    className="bg-green-900 text-white p-1 rounded-md max-sm:hidden">Alternative</span>
                                                            </div>
                                                        </dd>
                                                    </div>
                                                </dl>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>

                )) : ""}

            </main>
            <Footer/>
        </>
    );
};
