
import {Fragment, useEffect, useState} from 'react'
import Navbar from "../organisms/Navbar";
import Footer from "../organisms/Footer";
import {PageTitle} from "../atoms/PageTitle";
import CompaniesList from "../organisms/CompaniesList";
import Loader from "../molecules/Loader";

export default function Companies() {
    const [companies, setCompanies] = useState([]);
    const [renderedCompanies, setRenderedCompanies] = useState([]);
    const [loading, setLoading] = useState(true);

    useEffect(() => {
        async function getCompanies() {
            let response = await fetch('https://api.boycottisraeli.biz/v1/companies', {method: 'GET'});
            let status = await response.status
            if (status !== 200) {
                window.location.href = '/404';
            }
            let data = await response.json();
            setCompanies(data.data);
            setRenderedCompanies(data.data);
            await new Promise(resolve => setTimeout(resolve, 777));
            setLoading(false);
        }
        getCompanies();
    }, []);

    function search(term) {
        let filteredCompanies = companies.filter((company) => {
            return company.name.toLowerCase().includes(term.toLowerCase());
        });
        setRenderedCompanies(filteredCompanies);
    }
        return (
            <>
                <PageTitle title="Israeli Companies to Boycott and their Alternatives"/>
                <Navbar key="navbar"/>
                <header className="bg-gray-100 lg:px-40 sm:px-5">
                    <h1 className="lg:pt-7 sm:pt-3 pb-7 sm:pb-3 text-3xl font-bold tracking-tight text-gray-900 sm:text-4xl">
                        Search Israeli Companies to Boycott
                    </h1>
                </header>
                <main className="bg-gray-100 lg:px-40 sm:px-1 mb-11">
                    {
                        loading ? (
                            <Loader />
                        ) : (
                                <>
                                    <div className="bg-white mx-auto lg:grid lg:grid-cols-12 px-10">
                                        <div className="lg:col-span-12">
                                            <div className="relative mt-2 flex items-center">
                                                <input
                                                    type="text"
                                                    name="search"
                                                    id="search"
                                                    onChange={(e) => {search(e.target.value)}}
                                                    placeholder="Search for a company to see if it is Israeli so you can boycott it. You will see the company on the left and alternatives on the right."
                                                    className="block w-full rounded-md border-0 py-1.5 pl-3 pr-14 text-gray-900 shadow-sm ring-1 ring-inset ring-gray-300 placeholder:text-gray-400 focus:ring-2 focus:ring-inset focus:ring-green-600 sm:text-sm sm:leading-6"
                                                />
                                                <div className="absolute inset-y-0 right-0 flex py-1.5 pr-1.5">
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <CompaniesList companies={renderedCompanies}/>
                                </>
                            )
                }

                </main>
                <Footer/>
            </>
        );
};
