import NavbarLogo from "../atoms/NavbarLogo";
import NavigationItem from "../atoms/NavigationItem";
import ArticlesNavigation from "./ArticlesNavigation";
import CategoriesNavigation from "./CategoriesNavigation";

export default function Navigation(props) {
    const navigation = props.navigation;
    const articles = props.articles;
    const categories = props.categories;
    return (
            <>
            <NavbarLogo />
            <div className="hidden md:block">
                <div className="ml-10 flex items-baseline space-x-4">
                    {navigation.map((item) => (
                        <NavigationItem key={item.id} item={item} />
                    ))}
                    <CategoriesNavigation categories={categories} />
                    <ArticlesNavigation articles={articles} />
                </div>
            </div>
            </>
    );

}