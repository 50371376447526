import {useNavigate} from "react-router-dom";

export default function NavbarLogo() {
    const navigate = useNavigate();
    return (
        <>
            <div className="flex flex-shrink-0">
                <div className="flex-shrink-0 flex items-center">
                </div>
                <div className="flex-shrink-0 flex items-center">
                    <a href="/" title="Boycott Israeli Businesses"
                       onClick={(e) =>
                       {
                          e.preventDefault();
                          navigate('/');
                       }
                   }
                   >
                    <img
                        className="h-8 relative border border-white"
                        src="/images/palestine-flag.svg"
                        alt="Boycott Israeli Businesses"
                    />
                </a>
                </div>
                <div className="ml-3 flex-shrink-0 flex items-center font-bold text-white">
                    <a href="/" title="Boycott Israeli Businesses">Boycott Israeli Businesses</a>
                </div>
            </div>
        </>
    );
}